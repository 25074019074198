/* eslint-disable */
import React from "react";
import axios from "axios";
import { getToken } from "./auth";

const version = "api/v1/";
export const basicUrl = `https://py.payment.teqneia.com`;
const instance = axios.create({
  baseURL: `${basicUrl}/${version}`,
});

instance.interceptors.request.use((config) => {
  config.headers.Passkey = `c10965618ee1c2353475a67abbcbcb7cd53aa6becbecbb01`;
  return config;
});

export const httpNonToken = instance;
export const endpoints = {
  user: `payment/user`,
  status: `payment/status`,
  paypalCaptureOrder: `payment/paypal/capture`,
  paypalOrder: `payment/paypal`,
  payment: `payment`,
  finishRenew: `payment/finishRenew`,
  finishRegister: `payment/finishRegister`,
  finishServiceRenew: `register/renew`,
  finishServiceUpgrade: `register/upgrade`,
  getway: `getway`,
  plan: `plan`,
  project: `plan/project`,
  promoCode: `promo`,
  gatewayConfig: `getway/config`,
  //
  paymobOrder: "payment/paymob",
  getway: "getway",
  //paytabs endpoints
  paytabs: "payment/paytabs/",
  response: `${basicUrl}/${version}payment/paytabs/response/`,
  redirect: `${basicUrl}/${version}payment/paytabs/redirect/`,
};
