/* eslint-disable */
import React from "react";
import { httpNonToken, endpoints } from "../globalConf/http-non-token";
import axios from "axios";

const checkPaymentStatus = async () => {
  try {
    const res = await axios.get(
      `https://py.payment.teqneia.com/api/v1/payment/status`
    );
    const response = res.status == 200 ? true : false;

    return res?.data?.results;
  } catch ({ response }) {}
};

export default checkPaymentStatus;
